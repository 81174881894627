import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Footer = () => (
  <Box mt={4} py={2} bgcolor="primary.main" color="black">
    <Container maxWidth="lg">
      <Typography variant="body2" align="center">
        © 2024 great of deeds LLC. All Rights Reserved.
      </Typography>
    </Container>
  </Box>
);

export default Footer;
