import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import shirtLeaves from '../assets/images/shirtLeaves.jpg';
import IntakeForm from './IntakeForm'; // Ensure you import your IntakeForm component

const MainContent = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Container maxWidth="lg">
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Box p={2}>
          <Typography variant="h5" component="h2" gutterBottom>
            Health Fitness Performance
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          We are thrilled to announce the relaunch of great of deeds, a dynamic lifestyle brand and coaching service founded by none other than two-time Olympian, Taraje Williams-Murray!
          great of deeds is more than just a brand; it’s a movement towards a healthier, more active lifestyle. 
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          Taraje brings his Olympic experience and passion for health and fitness whether you’re looking to start your journey or take it to the next level.
          great deeds fitness offers personalized training plans, Olympic-level insights, motivational support and a community of like-minded individuals. 
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          We understand the challenge of balancing a demanding career with personal health goals. That’s why our programs are 
          tailored to fit into your hectic schedule, offering efficient workouts and practical 
          wellness advice that integrate seamlessly into your busy lifestyle.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          Stay healthy and energized, even on your busiest days!
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" disabled onClick={() => { handleOpen(); }}>
            Intake Form
          </Button>
          <Button variant="contained" color="primary" onClick={() => { window.location.href = 'https://greatdeedsfitness.setmore.com/'; }}>
            Set Appointment
          </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box p={2}>
          <Typography variant="h5" component="h2" gutterBottom>
            About Our Brand
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Taraje is a NSCA Certified Strength and Conditioning Specialist and Tactical Strength and Conditioning Facilitator, NASM Corrective Exercise Specialist and USA Weightlifting Level 2 Coach.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Join our community to start or redirect your fitness journey. We offer personal training and coaching in home or remotely to groups and individuals. Start your journey by scheduling an assessment or consultation. We look forward to helping you achieve your goals!
          </Typography>
          <Button variant="contained" color="primary" onClick={() => { window.location.href = 'https://www.taraje.com/'; }} sx={{ align: 'center', display: 'block', margin: '0 auto' }}>
            About Taraje
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box p={2}>
          <Typography variant="h5" component="h2" gutterBottom>
            Shop
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Stay tuned as we are revamping our shop to deliver our amazing designs in high style and comfort.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
            <img src={shirtLeaves} alt="Shirt with leaves" style={{ width: '100%' }} />
        </Grid>
    </Grid>
    <Modal
      open={open}
      onClose={handleClose}
    >
        <Box sx={style}>
            <IntakeForm handleClose={handleClose} />
        </Box>
    </Modal>
    </Container>
    );
}

export default MainContent;