// src/components/404Page.jsx

import React from 'react';
import { Box, Typography } from '@mui/material';

export default function NotFound() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1">404</Typography>
                <p>Sorry, the page you are looking for does not exist.</p>
            <a href="/">Return to Home</a>
        </Box>
        <Box sx={{ flex: 1 }} />
        </Box>
    );

}