import React from 'react';
import logo from '../assets/images/neteru-b.png';
import { AppBar, Toolbar, Typography, Box, IconButton
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Header = () => (
  <AppBar position="static">
    <Toolbar>
      <Box component="img" src={logo} alt="great deeds" sx={{ width: '50px' }} />
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      </Typography>
      <IconButton color="inherit" href="https://www.linkedin.com/company/g-ds/?viewAsMember=true" size='small'>  
        <LinkedInIcon />
      </IconButton>
      <IconButton color="inherit" href="https://www.instagram.com/taraje.wm" size='small'>
        <InstagramIcon />
      </IconButton>
      <IconButton color="inherit" href="https://www.facebook.com/greatofdeeds/about" size='small'>
        <FacebookIcon />
      </IconButton>    </Toolbar>
  </AppBar>
);

export default Header;
