import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme';
import Header from './components/Header';
import MainContent from './components/MainContent';
import NotFound from './components/NotFound';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';


function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>

          <Header />
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
