import React, { useState } from 'react';
import { Container, Typography, TextField, Button, FormControl, Select, MenuItem, InputLabel, FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';

const IntakeForm = ({ handleClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    occupation: '',
    fitnessGoals: '',
    timeFrame: '',
    previousExperience: '',
    healthConditions: {
      heartIssues: false,
      highBp: false,
      jointPain: false,
      dizziness: false,
      boneIssues: false,
      medications: false,
      otherMedical: false,
      none: false,
    },
    personalBests: {
      squat: '',
      frontSquat: '',
      deadlift: '',
      benchPress: '',
      militaryPress: '',
      snatch: '',
      clean: '',
      jerk: ''
    },
    additionalInfo: ''
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setFormData(prevState => ({
        ...prevState,
        healthConditions: {
          ...prevState.healthConditions,
          [name]: checked
        }
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('http://yourapi.com/api/intake', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      console.log(result); // Handle response
      handleClose(); // Close modal after submission
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const nextStep = () => setCurrentStep((prevStep) => prevStep + 1);
  const prevStep = () => setCurrentStep((prevStep) => prevStep - 1);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Intake Form</Typography>
      {currentStep === 1 && (
        <Box>
          <TextField
            fullWidth
            label="First Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Last Name"
            name="surname"
            value={formData.surname}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Date of Birth"
            name="dob"
            type="date"
            value={formData.dob}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Gender</InputLabel>
            <Select
              value={formData.gender}
              label="Gender"
              name="gender"
              onChange={handleInputChange}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={nextStep}>
            Next
          </Button>
        </Box>
      )}
        {currentStep === 2 && (
            <Box>
            <TextField
                fullWidth
                label="Occupation"
                name="occupation"
                value={formData.occupation}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Fitness Goals"
                name="fitnessGoals"
                value={formData.fitnessGoals}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Time Frame"
                name="timeFrame"
                value={formData.timeFrame}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Previous Experience"
                name="previousExperience"
                value={formData.previousExperience}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <Button variant="contained" color="primary" onClick={prevStep}>
                Previous
            </Button>
            <Button variant="contained" color="primary" onClick={nextStep}>
                Next
            </Button>
            </Box>
        )}
        {currentStep === 3 && (
            <Box>
            <FormGroup>
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.heartIssues} onChange={handleInputChange} name="heartIssues" />}
                label="Heart Issues"
                />
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.highBp} onChange={handleInputChange} name="highBp" />}
                label="High Blood Pressure"
                />
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.jointPain} onChange={handleInputChange} name="jointPain" />}
                label="Joint Pain"
                />
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.dizziness} onChange={handleInputChange} name="dizziness" />}
                label="Dizziness"
                />
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.boneIssues} onChange={handleInputChange} name="boneIssues" />}
                label="Bone Issues"
                />
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.medications} onChange={handleInputChange} name="medications" />}
                label="Medications"
                />
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.otherMedical} onChange={handleInputChange} name="otherMedical" />}
                label="Other Medical Conditions"
                />
                <FormControlLabel
                control={<Checkbox checked={formData.healthConditions.none} onChange={handleInputChange} name="none" />}
                label="None"
                />
            </FormGroup>
            <Button variant="contained" color="primary" onClick={prevStep}>
                Previous
            </Button>
            <Button variant="contained" color="primary" onClick={nextStep}>
                Next
            </Button>
            </Box>
        )}
        {currentStep === 4 && (
            <Box>
            <TextField
                fullWidth
                label="Squat"
                name="squat"
                value={formData.personalBests.squat}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Front Squat"
                name="frontSquat"
                value={formData.personalBests.frontSquat}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Deadlift"
                name="deadlift"
                value={formData.personalBests.deadlift}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Bench Press"
                name="benchPress"
                value={formData.personalBests.benchPress}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Military Press"
                name="militaryPress"
                value={formData.personalBests.militaryPress}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Snatch"
                name="snatch"
                value={formData.personalBests.snatch}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Clean"
                name="clean"
                value={formData.personalBests.clean}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Jerk"
                name="jerk"
                value={formData.personalBests.jerk}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <Button variant="contained" color="primary" onClick={prevStep}>
                Previous
            </Button>
            <Button variant="contained" color="primary" onClick={nextStep}>
                Next
            </Button>
            </Box>
        )}
        {currentStep === 5 && (
            <Box>
            <TextField
                fullWidth
                label="Additional Info"
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" onClick={prevStep}>
                Previous
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
            </Box>
            </Box>
        )}
    </Container>
  );
};

export default IntakeForm;
